import { Image } from "react-grid-gallery";
import img1 from "../assets/Gallery/Gallery1.webp";
import img2 from "../assets/Gallery/Gallery2.webp";
import img3 from "../assets/Gallery/Gallery3.webp";
import img4 from "../assets/Gallery/Gallery4.webp";
import img5 from "../assets/Gallery/Gallery5.webp";
import img6 from "../assets/Gallery/Gallery6.webp";
import img7 from "../assets/Gallery/Gallery7.webp";
import img8 from "../assets/Gallery/Gallery8.webp";
import img9 from "../assets/Gallery/Gallery9.webp";
import img10 from "../assets/Gallery/Gallery10.webp";
import img11 from "../assets/Gallery/Gallery11.webp";
// export interface CustomImage extends Image {
//   original: string;
// }

export const images = [
  {
    src: img1,
    original: img1,
    width: 574,
    height: 574,
    // caption: "After Rain (Jeshu John - designerspics.com)",
  },
  {
    src: img2,
    original: img2,
    width: 320,
    height: 212,
    // caption: "Boats (Jeshu John - designerspics.com)",
  },
  {
    src: img3,
    original: img3,
    width: 320,
    height: 212,
    // caption: "Color Pencils (Jeshu John - designerspics.com)",
  },
  {
    src: img4,
    original: img4,
    width: 320,
    height: 213,
    // caption: "Red Apples with other Red Fruit (foodiesfeed.com)",
  },
  {
    src: img5,
    original: img5,
    width: 320,
    height: 183,
    // caption: "37H (gratispgraphy.com)",
  },
  {
    src: img11,
    original: img11,
    width: 480,
    height: 320,
    // tags: [{ value: "Nature", title: "Nature" }],
    // caption: "8H (gratisography.com)",
  },
  {
    src: img7,
    original: img7,
    width: 320,
    height: 190,
    // caption: "286H (gratisography.com)",
  },
  {
    src: img8,
    original: img8,
    width: 320,
    height: 148,
    // tags: [{ value: "People", title: "People" }],
    // caption: "315H (gratisography.com)",
  },
  {
    src: img9,
    original: img9,
    width: 320,
    height: 213,
    // caption: "201H (gratisography.com)",
  },
  {
    src: img10,
    original: img10,
    alt: "gallery10",
    width: 300,
    height: 320,
    // caption: "Big Ben (Tom Eversley - isorepublic.com)",
  },
  {
    src:img6,
    original: img6,
    alt: "gallery11",
    width: 320,
    height: 113,
    // tags: [{ value: "People", title: "People" }],
    // caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
  },
];
